import { Button, Space, Table } from "antd";
import { useForm } from "antd/es/form/Form";
import { ColumnType } from "antd/es/table";
import useModal from "antd/es/modal/useModal";
import { CheckOutlined } from "@ant-design/icons";

import { usePagination } from "../../hooks/usePagintation";
import { useListMerchants, useCreateMerchant, useUpdateMerchant } from "../../hooks/apis/merchant";

import { MerchantForm } from "./MerchantForm";

type MerchantsProps = {
  merchants: Array<{
    id: string;
    name: string;
    domainPrefix: string;
    isAdmin: boolean;
  }>;
};

export function MerchantList() {
  const pagination = usePagination({
    defaultPageSize: 30,
  });

  const { data: { merchants = [], total } = {}, isLoading: isMachinesLoading } = useListMerchants();
  const { mutateAsync: createMerchant } = useCreateMerchant();
  const { mutateAsync: updateMerchant } = useUpdateMerchant();

  const [form] = useForm<MerchantsProps["merchants"][number]>();

  const [modal, contextHolder] = useModal();

  const columns: ColumnType<MerchantsProps["merchants"][number]>[] = [
    {
      title: "名稱",
      dataIndex: "name",
    },
    {
      title: "子網域前綴",
      dataIndex: "domainPrefix",
    },
    {
      title: "管理權限",
      dataIndex: "isAdmin",
      render: isAdmin => isAdmin && <CheckOutlined />,
    },
    {
      title: "操作",
      width: 100,
      render: record => (
        <Space>
          <Button
            size="small"
            onClick={() => {
              modal.confirm({
                cancelText: "取消",
                content: <MerchantForm form={form} merchant={record} />,
                icon: null,
                okText: "確認",
                title: "編輯商戶",
                onOk: async () => {
                  const values = await form.validateFields();

                  return updateMerchant(values);
                },
              });
            }}
          >
            編輯商戶
          </Button>
        </Space>
      ),
    },
  ];

  const onCreateButtonClick = () => {
    modal.confirm({
      cancelText: "取消",
      content: <MerchantForm form={form} />,
      icon: null,
      okText: "確認",
      title: "新增商戶",
      onOk: async () => {
        const values = await form.validateFields();

        return createMerchant(values);
      },
    });
  };

  return (
    <Space direction="vertical" className="w-full">
      <div className="text-2xl font-bold">商戶管理</div>

      {contextHolder}

      <Table
        dataSource={merchants || []}
        columns={columns}
        loading={isMachinesLoading}
        pagination={{ ...pagination, total }}
        scroll={{ x: "max-content" }}
        size="small"
        rowKey="id"
        title={() => (
          <Button type="primary" onClick={onCreateButtonClick}>
            新增商戶
          </Button>
        )}
      ></Table>
    </Space>
  );
}
