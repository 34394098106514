import { Form, Select } from "antd";
import { map } from "lodash";

import { useListPermissions } from "../hooks/apis/permission";

type Props = {
  multiple?: boolean;
  required?: boolean;
};

export function PermissionSelector(props: Props) {
  const { data: { permissions } = {}, isLoading, isFetching } = useListPermissions();

  return (
    <Form.Item
      label="權限"
      name={props.multiple ? "permissionIds" : "permissionId"}
      rules={[{ required: props.required, message: "請選擇權限" }]}
    >
      <Select
        loading={isLoading || isFetching}
        mode={props.multiple ? "multiple" : undefined}
        optionFilterProp="label"
        options={
          map(permissions, permission => ({
            label: `${permission.name} (${permission.id})`,
            value: permission.id,
          })) || []
        }
        placeholder="請選擇權限"
      />
    </Form.Item>
  );
}
