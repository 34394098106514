import { useEffect } from "react";
import { Form } from "antd";
import { FormInstance } from "antd/es/form/Form";
import Input from "antd/es/input/Input";

import { PermissionSelector } from "../../../components/PermissionSelector";

type RoleProps = {
  form: FormInstance;
  role?: {
    id: number;
    isProtected: boolean;
    name: string;
    permissionIds: Array<string>;
  };
};

export function RoleForm(props: RoleProps) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form form={props.form} autoComplete="off" initialValues={props.role}>
      <Form.Item label="名稱" name="name" rules={[{ required: true, message: "請輸入名稱" }]}>
        <Input />
      </Form.Item>

      <PermissionSelector multiple />
    </Form>
  );
}
