import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { message } from "antd";

export function useListRoles() {
  return useQuery("roleList", async () => {
    const response = await axios.get<{
      roles: {
        id: number;
        isProtected: boolean;
        name: string;
        permissions: Array<{
          id: string;
          name: string;
          desc?: string;
        }>;
      }[];
    }>("/backend/admin/roles");

    return response.data;
  });
}

export function useCreateRole() {
  const queryClient = useQueryClient();

  return useMutation(
    ["role", "create"],
    (data: { isProtected: boolean; name: string; permissionIds: Array<string> }) => {
      return axios.post("/backend/admin/roles", data);
    },
    {
      onSuccess: () => {
        message.success("新增成功");
        queryClient.invalidateQueries("roleList");
      },
    }
  );
}

export function useUpdateRole() {
  const queryClient = useQueryClient();

  return useMutation(
    ["role", "update"],
    (data: { id: number; isProtected: boolean; name: string; permissionIds: Array<string> }) => {
      return axios.patch(`/backend/admin/roles/${data.id}`, data);
    },
    {
      onSuccess: () => {
        message.success("更新成功");
        queryClient.invalidateQueries("roleList");
      },
    }
  );
}
