import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { message } from "antd";
import { useAuthStore } from "../../stores/useAuthStore";

export function useListMerchants() {
  const authStore = useAuthStore();

  return useQuery(["merchantList", authStore.token], async () => {
    if (!authStore.token) return;

    const response = await axios.get<{ merchants: []; total: number }>("/backend/admin/merchants");

    return response.data;
  });
}

export function useCreateMerchant() {
  const queryClient = useQueryClient();

  return useMutation(
    ["merchant", "create"],
    (data: { name: string; domainPrefix: string; isAdmin: boolean }) => {
      return axios.post("/backend/admin/merchants", data);
    },
    {
      onSuccess: () => {
        message.success("新增成功");
        queryClient.invalidateQueries("merchantList");
      },
    }
  );
}

export function useUpdateMerchant() {
  const queryClient = useQueryClient();

  return useMutation(
    ["merchant", "update"],
    (data: { id: string; name: string; domainPrefix: string; isAdmin: boolean }) => {
      return axios.patch(`/backend/admin/merchants/${data.id}`, data);
    },
    {
      onSuccess: () => {
        message.success("更新成功");
        queryClient.invalidateQueries("merchantList");
      },
    }
  );
}
