import { map } from "lodash";
import { Button, Space, Table } from "antd";
import { useForm } from "antd/es/form/Form";
import { ColumnType } from "antd/es/table";
import useModal from "antd/es/modal/useModal";

import { useCreateRole, useListRoles, useUpdateRole } from "../../../hooks/apis/role";

import { RoleForm } from "./RoleForm";
import { Tag } from "../../../components/Tag";

type RoleListProps = {
  roles: Array<{
    id: number;
    isProtected: boolean;
    name: string;
    permissions: Array<{
      id: string;
      name: string;
      desc?: string;
    }>;
  }>;
};

export function RoleList() {
  const { data: { roles = [] } = {}, isLoading: isRolesLoading } = useListRoles();
  const { mutateAsync: createRole } = useCreateRole();
  const { mutateAsync: updateRole } = useUpdateRole();

  const [form] = useForm();

  const [modal, contextHolder] = useModal();

  const onEditButtonClick = (role: RoleListProps["roles"][number]) => {
    modal.confirm({
      title: "編輯角色",
      icon: null,
      content: (
        <RoleForm
          form={form}
          role={{
            ...role,
            permissionIds: map(role.permissions, "id"),
          }}
        />
      ),
      okText: "保存",
      cancelText: "取消",
      onOk: async () => {
        const values = await form.validateFields();

        return updateRole({
          id: role.id,
          ...values,
        });
      },
    });
  };

  const columns: ColumnType<RoleListProps["roles"][number]>[] = [
    {
      title: "名稱",
      width: 150,
      dataIndex: "name",
    },
    {
      title: "權限",
      width: 500,
      dataIndex: "permissions",
      render: permissions => (
        <div className="flex flex-wrap gap-2">
          {map(permissions, permission => (
            <Tag title={permission.name} key={permission.id} />
          ))}
        </div>
      ),
    },
    {
      title: "操作",
      width: 150,
      render: record => (
        <Space size="small">
          <Button size="small" type="primary" disabled={record.isProtected} onClick={() => onEditButtonClick(record)}>
            編輯
          </Button>
        </Space>
      ),
    },
  ];

  const onCreateButtonClick = () => {
    modal.confirm({
      cancelText: "取消",
      content: <RoleForm form={form} />,
      icon: null,
      okText: "確認",
      title: "新增角色",
      onOk: async () => {
        const values = await form.validateFields();

        return createRole(values);
      },
    });
  };

  return (
    <Space direction="vertical" className="w-full">
      <div className="text-2xl font-bold">角色管理</div>

      {contextHolder}

      <Table
        dataSource={roles || []}
        columns={columns}
        loading={isRolesLoading}
        pagination={false}
        scroll={{ x: "max-content" }}
        size="small"
        rowKey="id"
        title={() => (
          <Button type="primary" onClick={onCreateButtonClick}>
            新增角色
          </Button>
        )}
      ></Table>
    </Space>
  );
}
