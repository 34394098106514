import { useEffect } from "react";
import { Form, Radio } from "antd";
import { FormInstance } from "antd/es/form/Form";
import Input from "antd/es/input/Input";

type MerchantProps = {
  form: FormInstance;
  merchant?: {
    id?: string;
    name: string;
    domainPrefix: string;
    isAdmin: boolean;
    admin?: {
      email: string;
      displayName: string;
    };
  };
};

export function MerchantForm(props: MerchantProps) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form form={props.form} autoComplete="off" initialValues={props.merchant}>
      <Form.Item name="id" hidden></Form.Item>
      <Form.Item name="name" label="名稱" rules={[{ required: true, message: "請輸入名稱" }]}>
        <Input />
      </Form.Item>
      <Form.Item name="domainPrefix" label="子網域前綴" rules={[{ required: true, message: "請輸入子網域前綴" }]}>
        <Input />
      </Form.Item>
      <Form.Item name="isAdmin" label="管理權限" rules={[{ required: true, message: "請選擇管理權限" }]}>
        <Radio.Group
          options={[
            {
              label: "允許",
              value: true,
            },
            {
              label: "拒絕",
              value: false,
            },
          ]}
        />
      </Form.Item>
      {!props.merchant?.id && (
        <>
          <Form.Item
            name={["admin", "email"]}
            label="初始人員Email"
            rules={[{ required: true, message: "請輸入初始人員Email" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["admin", "displayName"]}
            label="初始人員顯示名稱"
            rules={[{ required: true, message: "請輸入初始人員顯示名稱" }]}
          >
            <Input />
          </Form.Item>
        </>
      )}
    </Form>
  );
}
