import { Space, Table } from "antd";
import { ColumnType } from "antd/es/table";

import { usePagination } from "../../../hooks/usePagintation";
import { usePermissions } from "../../../hooks/apis/permission";

type PermissionsProps = {
  permissions: Array<{
    id: string;
    name: string;
    desc?: string;
  }>;
};

export function PermissionList() {
  const pagination = usePagination({
    defaultPageSize: 20,
  });

  const { data: { permissions = [], total } = {}, isLoading: isPermissions } = usePermissions();

  const columns: ColumnType<PermissionsProps["permissions"][number]>[] = [
    {
      title: "名稱",
      dataIndex: "name",
    },
    {
      title: "描述",
      dataIndex: "desc",
    },
  ];

  return (
    <Space direction="vertical" className="w-full">
      <div className="text-2xl font-bold">權限管理</div>

      <Table
        dataSource={permissions || []}
        columns={columns}
        loading={isPermissions}
        pagination={{ ...pagination, total }}
        scroll={{ x: "max-content" }}
        size="small"
        rowKey="id"
      ></Table>
    </Space>
  );
}
