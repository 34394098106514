import { useState } from "react";
import { Button, Flex, Form, Input, message } from "antd";
import { useForm } from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";
import { GoogleCircleFilled } from "@ant-design/icons";
import { isEmpty } from "lodash";

import { useLogin } from "../../hooks/apis/auth";
import { emailRegister, emailLogin, login } from "../../utils/firebase";
import { FIREBASE_ERROR_MAPPING } from "../../constants/firebase";

export function Login() {
  const { mutateAsync: mainLogin } = useLogin();
  const [isLoading, setIsLoading] = useState(false);

  const [form] = useForm();

  const onLoginButtonClick = async () => {
    const values = await form.validateFields();

    setIsLoading(true);
    try {
      const resp = await emailLogin(values.email, values.password);
      if (!isEmpty(resp) && resp.idToken) {
        mainLogin({
          token: resp.idToken,
        });
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      message.error(FIREBASE_ERROR_MAPPING[error] || error);
    }
  };

  const onRegisterButtonClick = async () => {
    const values = await form.validateFields();

    setIsLoading(true);
    try {
      const resp = await emailRegister(values.email, values.password);
      if (!isEmpty(resp) && resp.idToken) {
        await mainLogin({
          token: resp.idToken,
        });
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      message.error(FIREBASE_ERROR_MAPPING[error] || error);
    }
  };

  return (
    <div className="flex items-center justify-center h-full">
      <div className="flex flex-col items-stretch rounded-lg shadow-lg p-4 gap-4 ">
        <span className="text-2xl font-bold self-center">登入</span>
        <div className="flex gap-4">
          <div className="justify-center flex flex-col gap-4 shrink-0 flex-grow basis-0">
            <Button
              className="w-full"
              icon={<GoogleCircleFilled />}
              onClick={async () => {
                setIsLoading(true);
                try {
                  const resp = await login();
                  if (!isEmpty(resp) && resp.idToken) {
                    await mainLogin({
                      token: resp.idToken,
                    });
                    setIsLoading(false);
                  }
                } catch (error: any) {
                  setIsLoading(false);
                  message.error(FIREBASE_ERROR_MAPPING[error] || error);
                }
              }}
              loading={isLoading}
            >
              Google 快速登入
            </Button>
          </div>
          <div className="justify-center shrink-0 border-l border-solid border-gray-200 border-r-0" />
          <Form form={form} className="shrink-0 flex-grow basis-0 mx-auto" style={{ maxWidth: 300 }}>
            <FormItem label="信箱" name="email" rules={[{ required: true, message: "請輸入信箱" }]}>
              <Input />
            </FormItem>
            <FormItem
              label="密碼"
              name="password"
              rules={[
                { required: true, message: "請輸入密碼" },
                {
                  validator: async (rule, value) => {
                    if (value && !/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z0-9]{8,20}$/.test(value)) {
                      throw new Error("必須同時包含至少一個大寫、小寫、數字 且長度需至少為8位數");
                    }
                  },
                },
              ]}
            >
              <Input.Password />
            </FormItem>

            <Flex gap={8} vertical className="mt-8">
              <Button
                className="w-full"
                type="primary"
                onClick={onLoginButtonClick}
                loading={isLoading}
                htmlType="submit"
              >
                Email 登入
              </Button>

              <Button className="w-full" onClick={onRegisterButtonClick} loading={isLoading}>
                Email 註冊
              </Button>
            </Flex>
          </Form>
        </div>
      </div>
    </div>
  );
}
