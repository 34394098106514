import React, { useLayoutEffect, useState } from "react";
import { Link, Navigate, Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import { first } from "lodash";
import axios from "axios";
import { MenuProps } from "rc-menu";
import { Avatar, Dropdown, Layout, Menu, Space } from "antd";
import { PartitionOutlined, LogoutOutlined, SettingOutlined } from "@ant-design/icons";
import { Content, Header } from "antd/es/layout/layout";

import "./App.css";

import { PrivateRoute, PublicRoute } from "./route";
import { useAuthStore } from "./stores/useAuthStore";

import { Login } from "./pages/login/Login";
import { MerchantList } from "./pages/merchants/MerchantList";
import { RoleList } from "./pages/settings/role/RoleList";
import { PermissionList } from "./pages/settings/permission/PermissionList";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label: children ? (
      label
    ) : (
      <Link to={key.toString()} key={key}>
        {label}
      </Link>
    ),
    type,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("商戶管理", "/merchants/", <PartitionOutlined />),
  getItem("系統設定", "/settings", <SettingOutlined />, [
    getItem("角色管理", "/settings/roles"),
    getItem("權限列表", "/settings/permissions"),
  ]),
];

const App: React.FC = () => {
  const location = useLocation();
  const auth = useAuthStore();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [collapsed, setCollapsed] = useState(true);

  useLayoutEffect(() => {
    if (token && !auth.token) {
      auth.login(token);
    }
  }, [token, auth.login, auth, auth.token]);

  useLayoutEffect(() => {
    if (auth.token) {
      axios.interceptors.request.clear();
      axios.interceptors.request.use(config => {
        config.headers.Authorization = `Bearer ${auth.token}`;

        return config;
      });
    }
  }, [auth.token]);

  useLayoutEffect(() => {
    axios.interceptors.response.clear();

    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response?.status === 401) {
          auth.logout();
        }

        throw error;
      }
    );
  }, [auth, auth.logout]);

  return (
    <Layout className="min-h-[100vh] max-h-[100vh] h-[100vh]">
      {auth.token && (
        <Sider collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>
          <Menu theme="dark" mode="inline" items={items} selectedKeys={[location.pathname]} />
        </Sider>
      )}
      <Layout>
        {auth.token && (
          <Header className="flex justify-between items-center px-4">
            <span className="flex items-center text-2xl font-bold text-gray-200">主控台</span>

            <Dropdown
              menu={{
                items: [
                  {
                    key: "logout",
                    icon: <LogoutOutlined />,
                    label: "登出",
                    onClick: auth.logout,
                  },
                ],
              }}
            >
              <Space>
                <Avatar size="large" src={auth.user?.photoUrl} className="bg-gray-200 text-[#001529]">
                  {first(auth.user?.displayName?.split(""))}
                </Avatar>
                <span className="text-gray-200">{auth.user?.displayName}</span>
              </Space>
            </Dropdown>
          </Header>
        )}
        <Content className="p-4 overflow-auto">
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route
                path="merchants"
                children={[
                  <Route index key="index" element={<MerchantList />} />,
                  <Route path="*" key="default" element={<Navigate to="" replace />} />,
                ]}
              />
              <Route
                path="settings"
                children={[
                  <Route index key="index" element={<Navigate to="roles" replace />} />,
                  <Route key="roles" path="roles" element={<RoleList />} />,
                  <Route key="permissions" path="permissions" element={<PermissionList />} />,
                  <Route path="*" key="default" element={<Navigate to="" replace />} />,
                ]}
              />
              <Route path="*" element={<Navigate to="merchants" replace />} />
            </Route>
            <Route element={<PublicRoute />}>
              <Route path="login" element={<Login />} />
            </Route>
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
