import axios from "axios";
import { useMutation } from "react-query";
import { useAuthStore } from "../../stores/useAuthStore";

export function useLogin() {
  const authStore = useAuthStore();

  return useMutation(
    ["login"],
    (data: { token: string }) => {
      return axios.post("/backend/auth/login", data);
    },
    {
      onSuccess: data => {
        authStore.login(data.data.accessToken);
      },
    }
  );
}
