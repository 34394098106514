import { useQuery } from "react-query";
import axios from "axios";

export function useListPermissions() {
  return useQuery("permissionList", async () => {
    const response = await axios.get<{
      permissions: Array<{
        id: number;
        name: string;
      }>;
    }>("/backend/admin/permissions/list");
    return response.data;
  });
}

export function usePermissions() {
  return useQuery("permissions", async () => {
    const response = await axios.get<{
      permissions: Array<{
        id: string;
        name: string;
        desc?: string;
      }>;
      total: number;
    }>("/backend/admin/permissions");
    return response.data;
  });
}
