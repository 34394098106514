import { initializeApp } from "firebase/app";
import {
  getAuth,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

const firebase = initializeApp(firebaseConfig);
let auth = getAuth(firebase);

export const login = async () => {
  try {
    const googleProvider = new GoogleAuthProvider();
    const resp = await signInWithPopup(auth, googleProvider);
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(resp);
    // The signed-in user info.
    const user = resp.user;
    const idToken = await user.getIdToken();

    return {
      ...resp,
      ...credential,
      idToken,
    };
  } catch (error: any) {
    throw error.code;
  }
};

export const emailRegister = async (email: string, password: string) => {
  try {
    await createUserWithEmailAndPassword(auth, email, password);
    return await emailLogin(email, password);
  } catch (error: any) {
    throw error.code;
  }
};

export const emailLogin = async (email: string, password: string) => {
  try {
    const resp = await signInWithEmailAndPassword(auth, email, password);
    // const userCridential = EmailAuthProvider.credential(email, password);
    // const resp = await signInWithCredential(auth, userCridential);
    const user = resp.user;
    const idToken = await user.getIdToken();

    return {
      ...resp,
      idToken,
      ...resp.user,
    };
  } catch (error: any) {
    throw error.code;
  }
};

export const logout = async () => {
  try {
    await signOut(auth);
  } catch (e) {}
};
